import MakerArray from "./../datas/maker";

const analyze = params => {
    let makerList = [],
    specList = [],
    checkList = [];

    let device;
    switch (params.kind) {
        case 1:
            device = "notebook";
            break;
        case 2:
            device = "desktop";
            break;
        default:
            device = "allpc"
    }

    // ノートPCかデスクトップPCか？
    let isDeskTop = true;
    if (device === "desktop") {
        specList.push('種類:デスクトップパソコン');
    } else if (device === "notebook") {
        specList.push('種類:ノートパソコン');
        isDeskTop = false;
    } else if (device === "allpc") {
        if (params.goOut === 3 /*often*/) {
            specList.push('種類:ノートパソコン');
            isDeskTop = false;
        } else {
            let deskTopScore = 0;
            let noteScore = 0;
            if (params.stickShoulder === 2) {
                checkList.push('デスクトップパソコンの方がキーボードを自由な位置へ動かしやすく、また画面も大きいため目や肩に負担を掛けにくい');
                deskTopScore += 2;
            }
            if (params.multidisplay === 2) {
                checkList.push('モニターを複数使う場合はデスクトップPCを購入し、できれば同じサイズのモニターを平行に2枚並べると効率が良い');
                deskTopScore++;
            }
            if (params.cospa === 5) {
                deskTopScore++;
                checkList.push('ノートパソコンは省電力設計のCPUを用いているため、デスクトップパソコンの方がコストパフォーマンスは高い');
            }
            if (params.goOut === 2) {
                noteScore += 2;
            }
            if (params.goOut === 4) {
                noteScore++;
            }
            if (params.inHouse === 2) {
                noteScore +=2;
            }
            if (params.inHouse === 4) {
                noteScore++;
            }
            if (deskTopScore > noteScore) {
                specList.push('種類:デスクトップパソコン');
            } else {
                specList.push('種類:ノートパソコン');
                isDeskTop = false;
            }
            if (deskTopScore >= 1 && noteScore >= 1) {
                let tempStr = "使い方からは"
                if (isDeskTop) {
                    tempStr += "デスクトップ";
                } else {
                    tempStr += "ノート";
                }
                checkList.push(tempStr + 'をおすすめするが、持ち運びができるノートの利点と快適なデスクトップの利点を合わせ、最終的にどちらにすべきか判断しよう');
            }
        }
    }

    // ディスプレイ 解像度1366x768かそれ以上(FullHD(1920x1080)など)
    if (isDeskTop) {
        if (params.movieMain === 2) {
            specList.push('ディスプレイ: 解像度->FullHD サイズ-23.8 ~ 27インチ程度');
            checkList.push('迫力のある映像を楽しみたいならば大きなディスプレイを選んでも良いだろう');
        } else {
            specList.push('ディスプレイ: FullHD 23.8インチ程度');
        }
    }

    //ノートパソコンの選び方
    let mobile = false;
    let mobility = false;
    let standard = true;
    let gamingnote = false;
    if (!isDeskTop) {
        // サイズ
        if (params.goOut === 1) {
            specList.push('サイズ:15.4インチ以上');
            if (params.game === 2 || params.editProMovie === 2) {
                gamingnote = true;
            } else {
                standard = true;
                checkList.push('15.6インチ程度がコスパが高く作業効率も良い、ただ、家での使い方によっては13.3インチ程度がコンパクトで良い場合もあるので状況に応じて考えれば良い');
            }
        }
        if (params.goOut >= 2) {
            if (params.game === 2 || params.editProMovie === 2) {
                specList.push('サイズ:15.6インチ');
                checkList.push('ゲームや動画編集を本格的に行うならば、携帯性はやや犠牲にせざるを得ない');
            } else {
                mobility = true;
                if (params.goOut === 2) {
                    standard = true;
                    specList.push('サイズ:13.3インチ/14.1インチ');
                    checkList.push('持ち運び時の快適さを優先するなら13.3インチ、作業のしやすさをとるなら14.1インチのサイズがおすすめ');
                } else if (params.goOut === 3) {
                    mobile = true;
                    specList.push('サイズ:13.3インチ(12.4インチ程度まで可)');
                    checkList.push('持ち運ぶ頻度が高ければ、できれば1.2kg以内のものがおすすめ');
                    checkList.push('バッテリー駆動時間に注意しよう。おおよそ記載されている時間の7割が実際に動作する時間である');
                } else {
                    standard = true;
                    specList.push('サイズ:14.1インチ');
                    checkList.push('外出先で使う可能性があるならばギリギリ持ち運べる14.1インチ程度がおすすめ');
                }
            }
        }
        if (params.game === 2) {
            mobility = true;
            checkList.push('発熱等を考えるとゲーミングノートのHDDは外付けが良い。またゲームをHDDに入れると遅くなるためあくまで動画保存などの用途で使おう');
        }
    }

    //デスクトップパソコンの選び方
    let allinone = false;
    let tower = false;
    if (isDeskTop) {
        if (params.space === 5 && params.customize <= 3 && params.wire >= 2 && params.game === 1 && params.lightGame === 1 && params.multidisplay !== 2) {
            specList.push('筐体:ディスプレイと本体の一体型');
            allinone = true;
        } else if (params.space === 4 && params.customize <= 3 && params.wire === 2 && params.game === 1 && params.lightGame === 1 && params.multidisplay !== 2) {
            specList.push('筐体:ディスプレイと本体の一体型');
            allinone = true;
        } else if (params.space <= 4 && params.customize === 5) {
            tower = true;
            specList.push('筐体:タワー型(大型)パソコン');
            if (params.space === 4) {
                checkList.push('拡張性を確保するためには、省スペースはある程度犠牲になる');
            }
        } else if (params.space <= 3 && (params.customize >= 4 || params.game >= 2)) {
            tower = true;
            specList.push('筐体:タワー型(大型)パソコン');
        } else if (params.game === 3) {
            tower = true;
            specList.push('筐体:タワー型(大型)パソコン');
            if (params.space >= 4) {
                checkList.push('VRや海外の最新ゲームが遊びたい場合、省スペースを目指すならばニタワーと呼ばれるPCがおすすめ');
            }
        } else {
            if (params.game >= 2) {
                tower = true;
                specList.push('筐体:タワー型(ミニタワー)パソコン');
                checkList.push('本格的な3Dゲームをする可能性が高い場合は、拡張性を考えてやや大きめのミニタワー型パソコンの購入を考えよう。');
            } else {
                specList.push('筐体:スリム型パソコン(幅10cm程度、ディスプレイと本体は分離)');
            }
        }
    }

    // CPU
    let cpuPoint = 0;
    if (params.editMovie >= 2) {
        cpuPoint++;
    }
    if (params.officePersonal >= 2 || params.officeBusiness >= 2) {
        cpuPoint++;
    }
    if (params.moreThanOne >= 2) {
        cpuPoint++;
    }
    if (params.often === 3) {
        cpuPoint++;
    }
    if (params.often === 1) {
        cpuPoint-= 2;
    }
    if (params.often === 2) {
        cpuPoint-= 1;
    }
    if (params.internetMain === 2) {
        cpuPoint--;
    }
    if (params.notGoodAt === 1 || params.support >= 4) {
        cpuPoint++;
    }
    if (isDeskTop && !allinone) {
        if (params.game === 2 || params.editProMovie === 2) {
            specList.push('CPU: Core 5、余裕があればCore 7');
            if (params.game === 2) {
                checkList.push('高いフレームレートでより滑らかな映像を目指す場合はCore 7がおすすめ');
            }
        } else if ((params.game === 3 || params.lightGame >= 2) || params.editMovie >= 2 || params.editProMovie === 3) {
            specList.push('CPU: Core 5');
        } else {
            if (cpuPoint >= 1) {
                specList.push('CPU: Core 3');
            } else {
                specList.push('CPU: Celeron');
            }
        }
    } else {
        if (params.game >= 2 || params.editProMovie === 2) {
            specList.push('CPU: Core 7');
            if (params.game >= 2) {
                checkList.push('本格的なゲームがしたい場合はゲーミングノートPCを必ず購入しよう。CPUの種類などがゲーム向きになっている。(お尻がHなど)');
            }
        } else if (params.lightGame >= 2) {
            specList.push('CPU: Core 5、余裕があればCore 7');
        } else if (params.editMovie === 2 || params.editProMovie === 3) {
            specList.push('CPU: Core 5、余裕があればCore 7');
            checkList.push('CPUパワーが弱いノートパソコンで動画の編集を行うにはそれなりのスペックが求められる');
        } else {
            if (cpuPoint >= 2) {
                specList.push('CPU: Core 5');
            } else if (cpuPoint >= 0) {
                specList.push('CPU: Core 3');
            } else {
                if (params.often === 1) {
                checkList.push('使用頻度がそれほど高くないのであれば、コストパフォーマンスの点から低速なCPUで構わない');  
                }
                specList.push('CPU: CeleronかCore 3');
            }
        }
    }

    // メモリ
    let memoryPoint = 0;
    if (params.often <= 2) {
        memoryPoint--;
    }
    if (params.multiTab >= 2) {
        memoryPoint++;
    }
    if (params.multitask >= 2) {
        memoryPoint++;
    }
    if (params.notGoodAt === 2) {
        memoryPoint++;
    }
    if (params.game === 2 || params.editProMovie >= 2) {
        specList.push('メモリ: 16GB');
        if (params.editProMovie === 2) {
            checkList.push('本格的な動画編集ではメモリを使う機会が多いため32GB積んでも良いだろう');
        }
    } else if (params.game === 3 || params.lightGame >= 2 || params.editMovie >= 2) {
        specList.push('メモリ: 8GB');
    } else {
        if (memoryPoint >= 1) {
            specList.push('メモリ: 8GB');
        } else {
            specList.push('メモリ: 4GB');
            checkList.push('ライトな使い方なのでメモリは4GBでも一応問題はない');
        }
    }

    // グラフィックボード
    if (params.game === 2) {
        specList.push('グラフィックボード: Geforce RTX 4070 あるいは 4060Tiなど');
        if (isDeskTop) {
            checkList.push('4K画質や、光の描写をリアルにするレイトレーシング、高フレームレートを目指す場合はGeforce RTX 4070などハイエンドGPUの搭載を推奨。専用のゲーミングモニタや4Kモニタも必要。');
        }
    } else if (params.editProMovie === 2) {
        specList.push('グラフィックボード: 何かしらを予算に応じて搭載');
        checkList.push('特に3D系のCGを扱う場合にはハイエンドなもので無くても問題ないが、何かしらグラフィックボードを搭載しておいた方が良い。');
    } else if (params.lightGame >= 3 || params.game >= 3) {
        if (isDeskTop) {
            checkList.push('タワー(ミニタワー型)のPCならばゲームがしたくなった時にグラフィックボードを増設できる。');
        }
        if (params.lightGame === 3) {
            if (mobile) {
                checkList.push('Intel Core Ultra 7 165HなどIntel HシリーズやPシリーズのCPUはグラフィックボード無でもそこそこ3Dゲームを楽しめる。');
            }
        }
    } else if (params.lightGame === 2) {
        specList.push('グラフィックボード: Geforce RTX 4060');
        if (!isDeskTop && params.goOut >= 2) {
            checkList.push('Intel Core Ultra 7 165HなどIntel HシリーズやPシリーズのCPUはグラフィックボード無でもそこそこ3Dゲームを楽しめる。');
        }        
    }

    // ストレージ　(SSDかHDDかその両方か)　また容量は
    if (params.game >= 2 || params.lightGame >= 2 || params.editProMovie >= 2) {
        if (params.game === 2 && params.editProMovie === 2) {
            specList.push('SSD: 1TB');
        } else {
            specList.push('SSD: 512GB');
        }
    } else {
        if (!params.notGoodAt && (params.often === 1 || params.moreThanOne <= 1 || params.internetMain)) {
            specList.push('SSD: 128GB');
            checkList.push('ネット中心や使用頻度が低く、保存するファイルがほとんど無いならば128GBでも問題ない');
        } else {
            specList.push('SSD: 256GB');
        }
    }

    let hasHDD = true;
    const _hddmsg = (msg) => {
        if (mobility) {
            specList.push(`HDD: ${msg}(外付けで対応)`);
        } else {
            specList.push(`HDD: ${msg}`);
            checkList.push('外付けハードディスクやGoogle Driveなどのクラウドストレージにデータを保存し、その分容量を減らしても良い');
        }
    }
    if (params.recordTV === 2　|| params.muchStorage >= 2) {
        if (params.recordTV === 2 && params.muchStorage >= 2 && isDeskTop) {
            _hddmsg('2TB以上');
        } else {
            _hddmsg('1TB以上');
        }
        if (params.recordTV === 2) {
            checkList.push('1TBでおよそ200時間の録画が可能');
        }
    } else if (params.recordTV === 3 || params.fourKMovie === 2) {
        _hddmsg('1TB');
        if (params.fourKMovie) {
            checkList.push('iPhoneの高画質な4K(60fps)は1時間撮影で約25GBを消費する。4K動画を撮りためる場合はある程度ストレージを積んでおくと良い');
        }
        checkList.push('1TBでおよそ200時間の録画が可能');
    }　else {
        hasHDD = false;
    }
    if (hasHDD) {
        checkList.push('大容量の動画ファイルやバックアップ用途にHDDを用いる');
    }

    // 光学ドライブ
    const _drivemsg = (msg) => {
        if (mobility) {
            specList.push(`光学ドライブ: ${msg}(外付けで対応)`);
        } else {
            specList.push(`光学ドライブ: ${msg}`);
        }
    }
    if (params.playBD === 2) {
        _drivemsg('BDディスクドライブ');
    } else if (params.playDVD === 2) {
        _drivemsg('DVD(スーパー)マルチドライブ');
    } else if (isDeskTop) {
        _drivemsg('DVD(スーパー)マルチドライブ');
        checkList.push('一般的にデスクトップPCにはDVDドライブは付属するため必要でなくともついてくる');
        if (params.playBD === 3) {
            checkList.push('BDディスクドライブは高価なため、必要になった時に外付けで買えば問題ない');
        }
    } else {
        specList.push('光学ドライブ: 無し');
        checkList.push('CD/DVD/BDが必要になった場合は外付けドライブで対応すれば良い');
    }

    // Office
    if (params.officeBusiness === 2) {
        specList.push('オフィスソフト: Microsoft(R) Office Home and Business (Premium)');
    } else if (params.officePersonal === 2) {
        specList.push('オフィスソフト: Microsoft(R) Office Personal (Premium)');
    } else if (params.student === 2 && params.officeBusiness === 3) {
        specList.push('オフィスソフト: Microsoft(R) Office Home and Business (Premium)');
        checkList.push('大学生は研究発表やゼミでパワーポイントを使用するため、Home and Businessを選択するべきである。また社会人でもオフィスソフトを使うため、慣れ親しむためにも購入しておくとよい');
    } else if (params.officePersonal === 3 && params.firstBuy === 2) {
        specList.push('オフィスソフト: Microsoft(R) Office Personal (Premium)');
        checkList.push('初めてパソコンを購入する場合には、オフィスソフトを一つはもっておくと良い。日常的に使えるシーンは多い');
        if (params.officeBusiness === 3) {
            checkList.push('日常的なパワーポイントの使い方としては旅行のしおり、動画のスライドショー作成など、気になる場合はOffice Home and Businessに変更しても良い');
        }
    } else {
        specList.push('オフィスソフト: 無し');
    }
    // 大学生注意事項
    if (params.student === 2 && !isDeskTop && params.goOut === 1) {
        checkList.push('将来的に就職活動やゼミの発表でパソコンを持ち運ぶことを考えると13.3インチ程度のパソコンを買うことを検討しても良い');
    }

    // テレビ
    if (params.showTV === 2　|| params.recordTV === 2) {
        if (isDeskTop && allinone) {
        　　specList.push('映像機能: 地デジTVチューナー');
        } else {
            checkList.push('テレビ視聴・録画をしたい場合は<a href="https://amzn.to/2BInBSB" target="_blank">外付け地デジTVチューナ－</a>を購入しよう。ちょっと見たいぐらいなら画質は落ちるが<a href="https://amzn.to/30m2srg" target="_blank">ワンセグチューナー</a>を購入するのもあり')
        }
    }

    // ワイヤレスマウス
    if (isDeskTop && params.wire >= 2) {
        specList.push('マウス/キーボード:　ワイヤレスマウス/ワイヤレスキーボード');
        checkList.push('配線がすっきりするためワイヤレスマウスとワイヤレスキーボードはおすすめ');
    }
    if (!isDeskTop && params.stickShoulder === 2 && params.moreThanOne >= 2) {
        specList.push('マウス:　ワイヤレスマウス');
        checkList.push('ノートパッドと比べて、マウスは操作が快適で長時間作業をしても疲れにくいためおすすめ');
    }

    // 無線通信IEEE 802.11ax準拠
    if (!isDeskTop && params.wirelessLan === 2) {
        specList.push('無線LAN: Wifi 6対応(IEEE 802.11ax準拠)');
        checkList.push('ルータが古いとWifi6対応できないので、<a href="https://amzn.to/2MbN7Vs" target="_blank">Wifi6対応のルータ</a>が必要');
    }

    // 結果から有用な値を抽出
    const hasGPU = specList.some(str => str.includes('グラフィックボード:'));


    makerList = MakerArray.filter(el => {
        if (isDeskTop) {
            if (!el.hasDesktop) {
                return false;
            }
            if (hasGPU) {
                return el.hasGaming;
            }
            if (allinone) {
                return el.hasAllInOne;
            }
            if (tower) {
                return el.hasTower;
            }
            return true;
        } else {
            if (!el.hasNote) {
                return false;
            }
            if (mobile) {
                return el.hasMobile;
            }
            if (gamingnote) {
                return el.hasGamingNote;
            }
            if (standard) {
                return el.hasStandard !== false;
            }
        }
        return false;
    });
    const min = Math.min(params.cospa, params.support, params.design);
    const n_costa = params.cospa - min + 1,
        n_support = params.support - min + 1,
        n_design = params.design - min + 1;
    const weightRatio = (n_costa + n_support + n_design)/3;
    makerList.sort((makerA, makerB) => {
        let score = n_costa * (makerB.cospa - makerA.cospa) +
                    n_support * (makerB.support - makerA.support) +
                    n_design * (makerB.design - makerA.design);
        
        let subscore = 0;
        if (device !== "notebook" && params.customize >= 4) {
            subscore += makerB.towerBonus - makerA.towerBonus;
        }
        if (device === "notebook") {
            if (params.battery >= 4 && mobile) {
                subscore += makerB.mobileBonus - makerA.mobileBonus;
            }
            if (params.business === 2) {
                subscore += makerB.businessBonus - makerA.businessBonus;
            }
            if (params.game === 2) {
                subscore += makerB.gamingnoteBonus - makerA.gamingnoteBonus;
            }
        }
        return score + 2 * weightRatio * subscore;
    });

    return {
        params,
        hasGPU,
        isDeskTop,
        makerList: makerList,
        specList: specList,
        checkList: checkList
    };
}

export default analyze;