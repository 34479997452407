const questions = [
  {
      question: '種類',
      name: 'kind',
      answers: ['ノート', 'デスクトップ', '未定'],
      labelIds: ['notebook', 'desktop', 'allpc'],
      checked: 3
  },
  {
      question: 'コストパフォーマンス(価格の割にハイスペック)',
      name: 'cospa',
      answers: [1,2,3,4,5],
      checked: 3
  },
  {
      question: 'サポート品質(メール・電話・マニュアルなど)',
      name: 'support',
      answers: [1,2,3,4,5],
      checked: 3
  },
  {
      question: 'デザインの洗練度',
      name: 'design',
      answers: [1,2,3,4,5],
      checked: 3
  },
  {
      question: 'カスタマイズ性(購入後にパーツを追加し機能拡張)',
      name: 'customize',
      answers: [1,2,3,4,5],
      checked: 3,
      targetDevice: 'desktoponly'
  },
  {
      question: '軽量・長時間バッテリー',
      name: 'battery',
      answers: [1,2,3,4,5],
      checked: 3,
      targetDevice: 'noteonly'
  },
  {
      question: 'Space(スペース):省スペースにこだわるか',
      name: 'space',
      answers: [1,2,3,4,5],
      checked: 3,
      targetDevice: 'desktoponly'
  },
  {
      question: 'デュアルディスプレイ(モニター2台)環境を構築したい',
      name: 'multidisplay',
      answers: ['いいえ','はい','分からない'],
      checked: 1,
      targetDevice: 'allpc'
  },
  {
      question: '家の様々な場所でパソコンがやりたい',
      name: 'inHouse',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1,
      targetDevice: 'allpc'
  },
  {
      question: '外出先で用いたいか',
      name: 'goOut',
      answers: ['主に家', '稀に', '用いたい', '分からない'],
      checked: 1,
      targetDevice: 'noteonly'
  },
  {
      question: '配線はすっきりさせたい',
      name: 'wire',
      answers: ['気にならない', 'はい', 'できれば'],
      checked: 1,
      targetDevice: 'desktoponly'
  },
  {
      question: '長時間のパソコン作業を行うと肩こりが気になる',
      name: 'stickShoulder',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: '3Dゲームもそこそこ動かしたい',
      name: 'lightGame',
      answers: ['いいえ', 'はい', '興味はある'],
      checked: 1
  },
  {
      question: '本格的な3DゲームやVRを楽しみたい。ただし数万円はコストが上がる可能性がある',
      name: 'game',
      answers: ['いいえ', 'はい', '興味はある'],
      checked: 1
  },
  {
      question: '動画を切り貼りしてテロップを付けるなど簡単な動画編集をしてみたい',
      name: 'editMovie',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: '4K動画編集や、3Dモデリング、複雑なエフェクトなど本格的な動画編集が行いたい',
      name: 'editProMovie',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: '動画の再生が主な使用用途である',
      name: 'movieMain',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: 'パソコンの主な目的はインターネットでWebサイトの利用である',
      name: 'internetMain',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: 'テレワークなどビジネスが主な目的である',
      name: 'businessMain',
      answers: ['いいえ', 'はい'],
      targetDevice: 'noteonly',
      checked: 1
  },
  {
      question: 'ブラウザでタブを4つ以上開けているのが普通だ',
      name: 'multiTab',
      answers: ['いいえ','はい','分からない'],
      checked: 1
  },
  {
      question: '平均して毎日１時間以上はパソコンを使う',
      name: 'moreThanOne',
      answers: ['いいえ','はい','分からない'],
      checked: 1
  },
  {
      question: 'パソコンを使う頻度は',
      name: 'often',
      answers: ['稀に','週に2,3回','毎日','分からない'],
      checked: 1
  },
  {
      question: 'パソコンの購入は初めてである',
      name: 'firstBuy',
      answers: ['いいえ','はい'],
      checked: 1
  },
  {
      question: 'パソコンに苦手意識がある',
      name: 'notGoodAt',
      answers: ['いいえ', 'はい'],
      checked: 1
  },
  {
      question: 'テレビをパソコンで見たい',
      name: 'showTV',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: 'テレビ番組を録画したい',
      name: 'recordTV',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: '大量に撮りためた動画やファイルがあり、新しいパソコンに移したい',
      name: 'muchStorage',
      answers: ['いいえ', '500GB以上', '1TB以上'],
      checked: 1
  },
  {
      question: '4K動画を撮り保存したい',
      name: 'fourKMovie',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: '音楽を聴きながらWebサイトを閲覧して、Excelを操作するなど複数のことを同時にこなしたい',
      name: 'multitask',
      answers: ['いいえ','はい','分からない'],
      checked: 1
  },
  {
      question: '今、あるいはこれから大学生である',
      name: 'student',
      answers: ['いいえ', 'はい'],
      checked: 1
  },
  {
      question: 'WordかExcelは必要か？',
      name: 'officePersonal',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: 'PowerPointは必要か？',
      name: 'officeBusiness',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: '家にWifiに繋がる端末が多く、速度や安定性が心配',
      name: 'wirelessLan',
      answers: ['いいえ', 'はい'],
      checked: 1,
      targetDevice: 'noteonly'
  },
  {
      question: '音楽CDを取り込みや、DVDの再生/書き込みが行いたい',
      name: 'playDVD',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: 'BDを再生/書き込みを行いたい',
      name: 'playBD',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  }
];

export default questions;