import React from "react"
import styled from "@emotion/styled"
import { mq } from "../utils/style"
import FlatButton from "./flatbutton"
import questions from "./question/questionnares"
import analyze from "./question/analyze"
import AccentBox from "./accentBox"
import BasicTable from "./basicTable"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import twIcon from "./images/tw_icon.png";

const qVer = 'v2';

const ContentWrapper = styled.div({
  '.cols2': {
    width:'50%',
  },
  '.cols3': {
    width:'33.3%',
  },
  '.cols4': {
    width: '25%',
  },
  '.cols5': {
    width: '20%',
  },
  [mq('mobile')] : {
    overflow: 'hidden',
    width: '100%',
    padding: '10px 0px',
    borderTop: '2px solid #000',
    borderBottom: '2px solid #000',
    div: {
      clear: 'left',
      overflow: 'hidden',
      padding: '5px 0 2px 0',
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li : {
      listStyle: 'none',
      float: 'left',
      textAlign: 'center',
    },
    input : {
      '&:checked + label' : {
        color: '#fff',
        backgroundColor: '#25A298',
        fontWeight: 700,
        borderColor: 'black',
      },
      display: 'none',
    },
    label : {
      width: '100%',
      display: 'inline-block',
      backgroundColor: '#f5f5f5',
      color: '#333',
      border: '1px solid',
      padding: '10px 0px'
    }
  },
  [mq('pc')]: {
    overflow: 'hidden',
    margin: 'auto',
    fontSize: '1.5rem',
    width: '490px',
    padding: '10px',
    border: '2px solid',
    borderRadius: '10px',
    div : {
      clear: 'left',
      overflow: 'hidden',
      padding: '2px 0',
      marginBottom: '10px',
      borderBottom: '1px solid #CCC',
    },
    ul : {
      margin: '3px 0 0 0',
      padding: 0,
    },
    li : {
      listStyle: 'none',
      float: 'left',
      textAlign: 'center',
    },
    input : {
      '&:checked + label' : {
        color: '#fff',
        backgroundColor: '#25A298',
        fontWeight: 700,
        borderColor: 'black',
      },
      display: 'none',
    },
    label : {
      display: 'inline-block',
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      padding: '5px 15px',
      backgroundColor: '#eee',
      color: '#444',
      border: '2px solid',
      borderRadius: '5px',
      cursor: 'pointer',
    }
  }
});

const queryShorter = {
  map: {
      11: "a",
      12: "b",
      13: "c",
      14: "d",
      15: "e",
      21: "f",
      22: "g",
      23: "e",
      24: "i",
      25: "j",
      31: "k",
      32: "l",
      33: "m",
      34: "n",
      35: "o",
      41: "p",
      42: "q",
      43: "r",
      44: "s",
      45: "t",
      51: "u",
      52: "v",
      53: "w",
      54: "x",
      55: "y",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5"
  },
  encode(str) {
      const isEncoded = !(/^[0-5]*$/.test(str.substring(2)));
      if (isEncoded) {
          return str;
      }
      const subStr = str.substring(2);
      let newStr = "";
      for (let i = 0; i < subStr.length; i+=2) {
          if (!subStr[i+1]) {
            newStr += this.map[subStr[i]];
          } else {
            newStr += this.map[subStr[i] + subStr[i+1]];
          }
      }
      return str.substring(0, 2) + newStr;        
  },
  decode(str) {
      const isAlreadyDecoded = /^[0-5]*$/.test(str.substring(2));
      if (isAlreadyDecoded) {
          return str;
      }
      const reverseShoterMap = Object.keys(this.map).reduce((accum, key) => {
          accum[this.map[key]] = key;
          return accum;
      }, {});
      const subStr = str.substring(2);
      let originStr = "";
      for (let i = 0; i < subStr.length; i++) {
        originStr += reverseShoterMap[subStr[i]];
      }
      return str.substring(0,2) + originStr;
  }
}

class Radio extends React.PureComponent {
  render() {
    const { question, handleChange, checkedValue } = this.props;
    return (
      <div key={question.name}>
      {question.question}
      <ul>
        {question.answers.map((answer, i) => {
          const id = (question.labelIds && question.labelIds[i]) || question.name + i;
          return (
            <li className={"cols" + question.answers.length} key={id}>
              <input
                type="radio"
                name={question.name}
                value={i + 1}
                id={id}
                checked={checkedValue === (i + 1)}
                onChange={handleChange}
              />
              <label htmlFor={id}>{answer}</label>
            </li>
          );
        })}
      </ul>
    </div>
    )
  }
};

const parseQuery = ({ location }) => {
  if (!location.search) {
    return;
  }
  const pairs = location.search.substring(1).split('&');
  const queryPair = pairs.find(el => el.split('=')[0] === 'q');
  if (!queryPair) {
    return;
  }
  let queryValue = queryPair.split('=')[1];
  if (!queryValue.includes(qVer)) {
    return;
  }
　// change encoded value to normal value
　return queryShorter.decode(queryValue).substring(2);
}

export default class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = questions.reduce((acc, cur) => {
      acc.answers[cur.name] = cur.checked;
      return acc;
    }, {
      showResult: false,
      answers: {},
    });
    this.formTop = React.createRef();
    this.sns = React.createRef(); // read social button ref
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      answers : {
        ...this.state.answers,
        [name]: parseInt(value) // checked value is changed to string
      }
    });
  }
  
  componentDidMount = () => {
    const queryValue = parseQuery(this.props);
    let nextState = {};
    if (queryValue) {
      nextState = questions.reduce((acc, cur, i) => {
        acc.answers[cur.name] = parseInt(queryValue[i]) || cur.checked;
        return acc;
      }, {
        showResult: true,
        answers: {},
      });
      localStorage.setItem('recomState', JSON.stringify(nextState.answers));
      localStorage.setItem('recomTime', (new Date()).getTime());
      nextState.analyzedValues = analyze(nextState.answers);
      this.setState(nextState);
    } else  {
      const answers = localStorage.getItem('recomState');
      if (answers) {
        const time = (new Date()).getTime() - localStorage.getItem('recomTime');
        if (time > 10 * 24 * 60 * 60 * 1000) { // if 10 days pass delete storage
          localStorage.removeItem('recomState');
          localStorage.removeItem('recomTime');
          return;
        }
        this.setState({
          showResult: true,
          answers: JSON.parse(answers),
          analyzedValues: analyze(answers),
        });
      }
    }
  }

  _renderContent = () => {
    const kind = this.state.answers['kind']; // 1 -> note, 2-> desktop
    return questions.map((question) => {
      if (kind === 1 && question.targetDevice === 'desktoponly') {
        return;
      }
      if (kind === 2 && question.targetDevice === 'noteonly') {
        return;
      }
      return (
        <Radio
          key={question.name}
          question={question}
          handleChange={this.handleChange}
          checkedValue={this.state.answers[question.name]}
        />
      );
    })
  }

  showResult = () => {
    const showResult = !this.state.showResult;
    if (showResult) {
      localStorage.setItem('recomState', JSON.stringify(this.state.answers));
      localStorage.setItem('recomTime', (new Date()).getTime());
    }
    this.setState({
      showResult: !this.state.showResult,
      analyzedValues: analyze(this.state.answers)
    });
    this.formTop.current.scrollIntoView({  
      behavior: 'smooth'  
    });
  }

  renderQuestionValues = () => {
    const obj = this.state.analyzedValues;
    const { makerList, specList, checkList } = obj;

    // urlの作成
    // querystringはアンケートの順番通りに並ぶ数値の羅列にv2がついたもの
    let queryString = qVer;
    questions.forEach(({name}) => {
      queryString += this.state.answers[name];
    });
    const encodedQueryString = queryShorter.encode(queryString);
    const { location } = this.props;
    const url = location.protocol + '//' + location.hostname + '/?q=' + encodedQueryString + '#re';
    
    // 推薦結果ツイート用テキスト
    let tweetText = "パソコン診断結果💻\n\n";
    specList.forEach((el) => {
        const els = el.split(':');
        if (['CPU','メモリ','SSD','HDD','グラフィックボード'].includes(els[0])) {
          if (els[0] === 'グラフィックボード') {
              tweetText += '\u2705 グラボ搭載\n'; // \u2705 means Unicode checkmark
          } else {
            tweetText += `\u2705 ${els[0]}:${els[1]}\n`;
          }
        }
    });
    tweetText += `\nメーカーは${makerList[0].fname}、${makerList[1].fname}、${makerList[2].fname}等\n\n#おすすめパソコン診断\n\n詳細\n`;
    // add bookmark /twitter script
    // add bookmark / twitter script

    return (
      <>
        <h2 className="accent">推薦結果</h2>
        <p>お疲れさまでした。あなたへおすすめのパソコンは以下のようになります。まずはおおよそ適合度順におすすめのメーカーを上から表示。(リンクは別ウインドウで表示)</p>
        <BasicTable>
          <thead>
          <tr><th>メーカー</th><th>特徴</th></tr>
          </thead>
          <tbody>
          {makerList.map((el) => {
            let addText = ""
            if (obj.params.goOut === 3 && el.url.includes("富士通")) {
              　addText = `。<a href="/fujitsu/" target="_blank" rel="nofollow noopener">クーポン</a>もあり`;
            }
            return (
              <tr key={el.name}>
                <td align="center"><div dangerouslySetInnerHTML={{__html: el.url}} /></td>
                <td><div dangerouslySetInnerHTML={{__html: el.description + addText}} /></td>
              </tr>
            );
          })}
          </tbody>
        </BasicTable>
        <p>次におすすめのパソコンスペック。CPU、メモリ、HDDは最低限抑えておきたいレベルを表示。十分ではあるが、よりハイスペックにするかは予算次第。</p>
        <AccentBox title="おすすめのパソコンスペック" type="dia">
        {specList.map((el, i) => {
          const els = el.split(':');
          return els[1] ?
            <li key={i}><span className="bold">{els[0]}</span>・・・{els[1]}</li> :
            <li key={i}><span className="bold">{els[0]}</span></li>
        })}
        </AccentBox>
        <p>最後に診断結果を元に考えておきたいポイントや補足事項等をまとめておきます。</p>
        <AccentBox title="補足事項" type="dia">
          {checkList.map((el, i) => <li key={i}><div dangerouslySetInnerHTML={{__html: el}} /></li>)}
        </AccentBox>
        <h2 className="accent">結果のシェア・保存</h2>
        <p>推薦結果は10日程度ブラウザに保存されるため、再訪問時に再びアンケートをやり直す必要はありません。その他URLやTweetからも推薦結果にすぐアクセスできます。</p>
        <div className="mobile-margin">
        <CopyToClipboard text={url} onCopy={() => this.setState({copied: true})}>
          <FlatButton className={"small"}>推薦結果をコピー</FlatButton>
        </CopyToClipboard>
        {this.state.copied ? <span style={{color: 'red'}}> Copied.</span> : null}
        <textarea id="copyTarget" value={url} style={{width: 'calc(100% - 20px)'}} readOnly />
        </div>
        <p className="mobile-margin">推薦結果をツイート！<br/>
        <a href={`http://twitter.com/share?url=${encodeURIComponent(url)}&lang=ja&text=${encodeURIComponent(tweetText)}`} target="_blank" rel="nofollow noopener noreferrer" style={{
          display: 'block',
          height: '35px',
          lineHeight: '35px',
          width: '150px',
          textAlign: 'center',
          overflow: 'hidden',
          borderRadius: '17px',
          background: `#55ACEE url(${twIcon}) center no-repeat`
        }}></a>
        </p>
      </>
    )
  }

  render() {
    const { showResult } = this.state;
    return (
      <>
        <div ref={this.formTop} id="formTop"></div>
        <div ref={this.sns} id="re"></div>
        {!showResult && <form>
          <ContentWrapper>
            <div>
              <ul>
                <li className="cols2" key="noweight">重視しない&lt;===</li>
                <li className="cols2" key="weight">===&gt;重視する</li>
              </ul>
            </div>
            { this._renderContent() }
            </ContentWrapper>
        </form>}
        {showResult && this.renderQuestionValues()}
        <div className="center top-margin">
          <FlatButton
            onClick={this.showResult}
          >
            <span css={{fontSize: '2.5rem'}}>{showResult ? 'アンケートに戻る' : '結果を表示'}</span>
          </FlatButton>
        </div>
      </>
    )
  }
};