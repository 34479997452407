const MakerArray = [
  {
      name: 'lenovo',
      fname: 'Lenovo',
      url: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=884764152" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=884764152" height="1" width="1" border="0">Lenovo</a>',
      description: 'IBMから分離した世界販売トップのメーカー、大量生産による価格競争力は随一',
      hasDesktop: true,
      hasAllInOne: true,
      hasTower: true,
      hasGaming: true,
      hasNote: true,
      hasMobile: true,
      hasGamingNote: true,
      cospa: 10,
      design: 5,
      support: 4,
      popular: 7,
      businessBonus: 2,
  },
{
    name: 'dell',
    fname: 'デル',
    url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10003522&type=3&subid=0" rel="nofollow noopener" target="_blank">デル</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10003522&type=3&subid=0" />',
    description: '高いコストパフォーマンスが魅力、世界有数の大パソコンメーカー、キャンペーンも多い',
    hasDesktop: true,
    hasAllInOne: true,
    hasTower: true,
    hasGaming: true,
    hasNote: true,
    hasMobile: true,
    hasGamingNote: true,
    cospa: 10,
    design: 6,
    support: 3,
    popular: 7,
},
{
    name: 'mouse',
    fname: 'マウスコンピュータ',
    url: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847755" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847755" height="1" width="1" border="0">マウスコンピュータ</a>',
    description: '安心感がある国内パソコンメーカーで人気が高い。幅広いラインナップが魅力',
    hasDesktop: true,
    hasAllInOne: false,
    hasTower: true,
    hasGaming: true,
    hasNote: true,
    hasMobile: false,
    hasGamingNote: true,
    cospa: 8,
    design: 5,
    support: 7,
    popular: 10,
    towerBonus: 3,
},
{
    name: 'koubou',
    fname: 'パソコン工房',
    url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0" >',
    description: 'BTOメーカーの老舗。コスパでパソコンで選ぶならば安定のパソコン工房',
    hasDesktop: true,
    hasAllInOne: false,
    hasTower: true,
    hasGaming: true,
    hasNote: true,
    hasMobile: false,
    hasGamingNote: true,
    cospa: 9,
    design: 5,
    support: 5,
    popular: 9,
    towerBonus: 3,
},
{
  name: 'fujitsu',
  fname: '富士通',
  url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=183092.10000156&type=3&subid=0" target="_blank" rel="nofollow noopener">富士通</a><IMG border=0 width=1 height=1 src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=183092.10000156&type=3&subid=0" >',
  description: 'バランスの良さはピカイチ、大手日系メーカー、国内シェアもNECに次ぐ',
  hasDesktop: true,
  hasAllInOne: true,
  hasTower: false,
  hasGaming: false,
  hasNote: true,
  hasMobile: true,
  hasGamingNote: false,
  cospa: 4,
  design: 8,
  support: 7,
  popular: 6,
  mobileBonus: 3,
},
{
    name: 'hp',
    fname: 'HP',
    url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=252926.507&type=3&subid=0" target="_blank" rel="nofollow noopener">HP</a><IMG border=0 width=1 height=1 src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.507&type=3&subid=0" >',
    description: '価格、デザインともに充実した世界有数のパソコンメーカー',
    hasDesktop: true,
    hasAllInOne: true,
    hasTower: true,
    hasGaming: true,
    hasNote: true,
    hasMobile: true,
    hasGamingNote: true,
    cospa: 7,
    design: 7,
    support: 5,
    popular: 6,
    mobileBonus: 1
},
{
    name: 'nec',
    fname: 'NEC',
    url: '<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=http%3A%2F%2Fnec-lavie.jp%2Fshop%2F%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0">NEC</a>',
    description: 'サポート体制が整っていて抜群の安定感を誇る日本メーカー。国内シェアも高い',
    hasDesktop: true,
    hasAllInOne: true,
    hasTower: false,
    hasGaming: false,
    hasNote: true,
    hasMobile: true,
    hasGamingNote: false,
    cospa: 4,
    design: 7,
    support: 9,
    popular: 5,
    mobileBonus: 2,
},
{
    name: 'dynabook',
    fname: 'Dynabook',
    url: '<a href="https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10000411&type=3&subid=0" target="_blank" rel="nofollow noopener">Dynabook</a><IMG border=0 width=1 height=1 src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10000411&type=3&subid=0" >',
    description: '東芝とシャープからなる会社。軽くて堅牢性が高いノートパソコン。ハイエンドモバイルノートを買うなら要検討',
    hasDesktop: false,
    hasNote: true,
    hasMobile: true,
    hasGamingNote: false,
    cospa: 4,
    design: 7,
    support: 8,
    soft: 7,
    popular: 5,
    mobileBonus: 3,
},
{
    name: 'microsoft',
    fname: 'Microsoft',
    url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=791390.9&type=3&subid=0" target="_blank" rel="nofollow noopener">Microsoft</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=791390.9&type=3&subid=0" >',
    description: 'デザイン、使い勝手を重視した洗練されたPC',
    hasDesktop: false,
    hasNote: true,
    hasMobile: true,
    hasGamingNote: false,
    cospa: 4,
    design: 10,
    support: 6,
    popular: 4,
},
{
  name: 'asus',
  fname: 'ASUS',
  url: '<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=858354.11&type=3&subid=0" target="_blank" rel="nofollow noopener">ASUS</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=858354.11&type=3&subid=0" >',
  description: 'ノートパッドにテンキーを準備など考えられたデザイン、長寿命バッテリーも魅力',
  hasDesktop: true,
  hasAllInOne: true,
  hasTower: true,
  hasGaming: true,
  hasNote: true,
  hasMobile: true,
  hasGamingNote: true,
  cospa: 7,
  design: 7,
  support: 4,
  soft: 5,
  popular: 5,
  gamingnoteBonus: 2,
  mobileBonus: 2,
　}
];

// insert default values
export default MakerArray.map(maker => {
  maker.mobileBonus = maker.mobileBonus || 0;
  maker.businessBonus = maker.businessBonus || 0;
  maker.towerBonus = maker.towerBonus || 0;
  maker.gamingnoteBonus = maker.gamingnoteBonus || 0;
  return maker;
});
